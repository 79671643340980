import { IconMail } from "@tabler/icons-react";
import { useFormik } from "formik";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "~/components/logo";
import { RenderTiptapContent } from "~/components/text-editor/content-renderer";
import { defaultRenderTypeMap } from "~/components/text-editor/default-renderer";
import { emptyTipTapContent } from "~/components/text-editor/tiptap-default-content";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { Dialog, DialogContent } from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { BasicSelect } from "~/components/ui/select";
import Text from "~/components/ui/text";
import { cn } from "~/lib/utils";
import {
  CreateAnswerSchema,
  FormFieldSchema,
  FormFieldTypeEnum,
  FormPublicSchema,
} from "~/types/backend";
import { useCreateFormAnswer } from "../api/formbuilder-hooks";

function FieldRow({
  label,
  input,
  error,
}: {
  label: string;
  input: React.ReactNode;
  error?: string;
}) {
  return (
    <div className="flex flex-col my-2">
      <div>
        <Text className="font-semibold">{label}</Text>{" "}
        <Text className="text-destructive-foreground">{error}</Text>
      </div>
      <div>{input}</div>
    </div>
  );
}

interface IFormField extends FormFieldSchema {
  answer: CreateAnswerSchema;
  error?: string;
}

interface TRenderFieldProps {
  field: IFormField;
  setFieldValue: (field_uuid: string, value: string | undefined) => void;
}
function RenderFieldString({ field, setFieldValue }: TRenderFieldProps) {
  return (
    <FieldRow
      label={`${field.label} ${field.required ? "*" : ""}`}
      input={
        <Input
          type="text"
          placeholder={field.placeholder}
          value={field.answer.value}
          onChange={(e) => setFieldValue(field.uuid, e.currentTarget.value)}
          error={field.error}
          className="h-9 w-full"
        />
      }
    />
  );
}

function RenderFieldInteger({ field, setFieldValue }: TRenderFieldProps) {
  return (
    <FieldRow
      label={`${field.label} ${field.required ? "*" : ""}`}
      input={
        <Input
          type="number"
          placeholder={field.placeholder}
          value={field.answer.value}
          onChange={(e) => setFieldValue(field.uuid, e.currentTarget.value)}
          error={field.error}
          className="h-9 w-full"
        />
      }
    />
  );
}

const serializeNameField = (first_name: string, last_name: string) => {
  return JSON.stringify({ first_name, last_name });
};

export const deserializeNameField = (name: string | undefined | null) => {
  if (!name) {
    return { first_name: "", last_name: "" };
  }
  const { first_name, last_name } = JSON.parse(name);
  return { first_name, last_name };
};

function RenderFieldName({ field, setFieldValue }: TRenderFieldProps) {
  const { first_name, last_name } = deserializeNameField(field.answer.value);
  return (
    <FieldRow
      label={`${field.label} ${field.required ? "*" : ""}`}
      input={
        <div className="flex space-x-2">
          <Input
            type="text"
            placeholder={"Ime"}
            value={first_name}
            onChange={(e) =>
              setFieldValue(
                field.uuid,
                serializeNameField(e.currentTarget.value, last_name)
              )
            }
            error={field.error}
            className="h-9"
          />
          <Input
            type="text"
            placeholder={"Prezime"}
            value={last_name}
            onChange={(e) =>
              setFieldValue(
                field.uuid,
                serializeNameField(first_name, e.currentTarget.value)
              )
            }
            error={field.error}
            className="h-9 "
          />
        </div>
      }
    />
  );
}

function RenderFieldEmail({ field, setFieldValue }: TRenderFieldProps) {
  return (
    <FieldRow
      label={`${field.label} ${field.required ? "*" : ""}`}
      input={
        <Input
          icon={IconMail}
          type="text"
          placeholder={field.placeholder}
          value={field.answer.value}
          onChange={(e) => setFieldValue(field.uuid, e.currentTarget.value)}
          error={field.error}
          className="h-9 w-full"
        />
      }
    />
  );
}
interface DisplayOptionsEmailConsent {
  consent_text: string;
}
function RenderFieldEmailConsent({ field, setFieldValue }: TRenderFieldProps) {
  const optionValues: DisplayOptionsEmailConsent = field.display_options
    ? (JSON.parse(field.display_options) as DisplayOptionsEmailConsent)
    : { consent_text: "" };
  return (
    <div className="mt-4 mb-2 ml-1">
      <div className="flex items-start ">
        <Checkbox
          checked={field.answer.value === "true"}
          onCheckedChange={(checked) =>
            setFieldValue(field.uuid, checked ? "true" : "false")
          }
          className={cn(
            "mt-[2px] size-5",
            field.error ? "border-destructive-foreground" : ""
          )}
        />
        <span className="ml-2 italic self-center">
          {`${optionValues.consent_text} ${field.required ? "*" : ""}`}
        </span>
      </div>
      {field.error && (
        <div className="ml-6 text-destructive-foreground text-sm font-medium">
          {field.error}
        </div>
      )}
    </div>
  );
}

interface DisplayOptionsSingleChoice {
  options: string[];
}

function RenderFieldSingleChoice({ field, setFieldValue }: TRenderFieldProps) {
  const optionValues: DisplayOptionsSingleChoice = field.display_options
    ? (JSON.parse(field.display_options) as DisplayOptionsSingleChoice)
    : { options: [] };
  return (
    <FieldRow
      label={`${field.label} ${field.required ? "*" : ""}`}
      input={
        <BasicSelect
          placeholder={field.placeholder}
          value={field.answer.value}
          onChange={(value) => setFieldValue(field.uuid, value)}
          className="h-9 w-full"
          options={optionValues.options.map((option) => ({
            value: option,
            label: option,
          }))}
        />
      }
      error={field.error}
    />
  );
}

function RenderFields({
  fields,
  setFieldValue,
}: {
  fields: IFormField[];
  setFieldValue: (field_uuid: string, value: string | undefined) => void;
}) {
  return (
    <div>
      {fields
        .sort((a, b) => a.order - b.order)
        .map((field) => {
          if (field.field_type === FormFieldTypeEnum.String) {
            return (
              <RenderFieldString
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          } else if (field.field_type === FormFieldTypeEnum.Integer) {
            return (
              <RenderFieldInteger
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          } else if (field.field_type === FormFieldTypeEnum.Name) {
            return (
              <RenderFieldName
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          } else if (field.field_type === FormFieldTypeEnum.Email) {
            return (
              <RenderFieldEmail
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          } else if (field.field_type === FormFieldTypeEnum.EmailConsent) {
            return (
              <RenderFieldEmailConsent
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          } else if (field.field_type === FormFieldTypeEnum.SingleChoice) {
            return (
              <RenderFieldSingleChoice
                key={field.uuid}
                field={field}
                setFieldValue={setFieldValue}
              />
            );
          }
        })}
    </div>
  );
}

export function FormPreview({
  form,
  open,
  setOpen,
}: {
  form: FormPublicSchema;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <FormRender form={form} />
        </DialogContent>
      </Dialog>
    </>
  );
}

const validateField = (
  field: IFormField,
  t: TFunction<"translation", undefined>
) => {
  if (
    field.required &&
    (field.answer.value === "" || field.answer.value === undefined)
  ) {
    return "This field is required.";
  }
  if (field.field_type === FormFieldTypeEnum.Email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(field.answer.value)) {
      return t("auth:signup.invalid_email");
    }
  }
  if (field.field_type === FormFieldTypeEnum.String) {
    if (field.answer.value.length > 2000) {
      return t("common:less_than_n_characters", { count: 2000 });
    }
  }
  return undefined;
};

function RenderSuccess({ form }: { form: FormPublicSchema }) {
  return (
    <div className="flex flex-col">
      <RenderTiptapContent
        content={JSON.parse(form.confirmation_message)}
        renderTypeMap={defaultRenderTypeMap}
      />
    </div>
  );
}

function RenderErrorMessage({ form }: { form: FormPublicSchema }) {
  return (
    <div className="flex flex-col">
      <RenderTiptapContent
        content={
          form.error_message
            ? JSON.parse(form.error_message)
            : emptyTipTapContent
        }
        renderTypeMap={defaultRenderTypeMap}
      />
    </div>
  );
}

export default function FormRender({
  form,
  token,
  hideLogo,
}: {
  form: FormPublicSchema;
  token?: string;
  hideLogo?: boolean;
}) {
  const { t } = useTranslation();

  const formResponseMutation = useCreateFormAnswer();
  const [showSuccess, setShowSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      fields: form.fields.map((field) => ({
        ...field,
        answer: {
          field_uuid: field.uuid,
          value: "",
        },
      })) as IFormField[],
    },
    validate: (values) => {
      let hasError = false;
      formik.setFieldValue(
        "fields",
        values.fields.map((field) => {
          const error = validateField(field, t);
          if (error) {
            hasError = true;
          }
          return {
            ...field,
            error: error,
          };
        }),
        false
      );
      if (hasError) {
        return { fields: values.fields };
      }
    },
    onSubmit: () => {
      formik.validateForm();
      if (formik.isValid && token) {
        formResponseMutation.mutate({
          token: token,
          data: {
            answers: formik.values.fields.map((field) => ({
              field_uuid: field.uuid,
              value: field.answer.value,
            })),
          },
        });
      } else if (formik.isValid) {
        // show confirmation message in preview
        setShowSuccess(true);
      }
    },
  });
  const setFieldValue = (field_uuid: string, value: string | undefined) => {
    formik.setFieldValue(
      "fields",
      formik.values.fields.map((field) => {
        if (field.uuid === field_uuid) {
          return { ...field, answer: { ...field.answer, value } };
        }
        return field;
      }),
      false
    );
  };
  if (formResponseMutation.isError) {
    if (formResponseMutation.error.response?.status === 409) {
      return (
        <div className="flex flex-col">
          {!hideLogo && (
            <div className="flex justify-center mb-2">
              <Logo className="h-[50px]" />
            </div>
          )}
          <Text variant="title">{form.title}</Text>
          <RenderErrorMessage form={form} />
        </div>
      );
    }
  }
  return (
    <>
      <div className="flex flex-col">
        {!hideLogo && (
          <div className="flex justify-center mb-2">
            <Logo className="h-[50px]" />
          </div>
        )}
        <Text variant="title">{form.title}</Text>

        {formResponseMutation.isSuccess || showSuccess ? (
          <RenderSuccess form={form} />
        ) : (
          <>
            <RenderTiptapContent
              content={JSON.parse(form.description)}
              renderTypeMap={defaultRenderTypeMap}
            />
            <RenderFields
              fields={formik.values.fields}
              setFieldValue={setFieldValue}
            />
            <div className="mt-4 flex justify-end">
              <Button size="xl" onClick={formik.submitForm}>
                Prijava
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
